<template>
  <div
    v-if="displayItem"
    class="accordion-item"
    :class="{ 'accordion-item--active': isItemActive }"
  >
    <h3 class="accordion-item__head row v-center h-justify" @click="toggleItem">
      <T3HtmlParser class="accordion-item__title" :content="item.name" />
      <div class="accordion-item__arrow row flex-center no-shrink" />
    </h3>

    <div class="accordion-item__body-wrapper">
      <div class="accordion-item__body">
        <T3HtmlParser class="accordion-item__text" :content="item.text" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { UiCeProductBoxesItemProps } from '~/types'

const props = defineProps<{
  item: Pick<UiCeProductBoxesItemProps, 'name' | 'text'>
}>()

const displayItem = computed(() => props.item.name && props.item.text)

const isItemActive = ref(false)

const toggleItem = () => {
  isItemActive.value = !isItemActive.value
}
</script>

<style lang="scss">
.accordion-item {
  border-bottom: 1px solid color(silver);

  $base: &;

  &__head {
    padding-top: rem(25px);
    padding-bottom: rem(25px);
    cursor: pointer;
    margin: 0;
  }

  &__body {
    min-height: 0;

    &-wrapper {
      display: grid;
      align-content: start;
      grid-template-rows: 0fr;
      overflow: hidden;
      transition: grid-template-rows 0.2s;

      #{$base}--active & {
        grid-template-rows: 1fr;
      }
    }
  }

  &__head,
  &__body {
    @include media-query(sm) {
      padding-right: em(20px);
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(16px);
    padding-right: 10%;
  }

  &__text {
    padding-bottom: rem(25px);
    font-size: rem(16px);
    line-height: 1.5;
    padding-right: 10%;

    & p:last-child {
      margin-bottom: 0;
    }

    @include themed-link;
  }

  &__arrow {
    position: relative;
    height: rem(32px);
    width: rem(32px);
    border: 2px solid transparent;
    border-radius: em(3px);
    transition: border-color 0.5s ease;

    $arrow-line-width: 2px;
    $arrow-size: 58%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      background-color: var(--theme__theme-color);
      transition:
        rotate 0.25s ease-out,
        background-color 0.25s ease-out;
    }

    &::before {
      width: $arrow-line-width;
      height: $arrow-size;
    }

    &::after {
      width: $arrow-size;
      height: $arrow-line-width;
    }

    #{$base}--active & {
      border-color: var(--theme__theme-color);

      &::before,
      &::after {
        background-color: color(black);
      }

      &::before {
        rotate: 90deg;
      }

      &::after {
        rotate: 180deg;
      }
    }
  }
}
</style>
